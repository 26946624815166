<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/index' }">PC网站</el-breadcrumb-item>
      <el-breadcrumb-item>下载文件</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="menu-area">
        <el-button type="primary" @click="toAdd">添加下载文件</el-button>
      </div>
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="title" label="文件名"></el-table-column>
        <el-table-column prop="type" label="文件格式"></el-table-column>
        <el-table-column prop="size" label="文件大小">
          <template slot-scope="scope">
            {{changesize(scope.row.size)}}
          </template>
        </el-table-column>
        <el-table-column prop="download" label="被下载次数"></el-table-column>
        <el-table-column fixed="right" label="操作" width="330">
          <template slot-scope="scope">
            <el-button size="small" type="success" @click="editItem(scope.row.id)">编辑</el-button>
            <el-button type="danger" size="small" @click="delItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      dataList: []
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      const { data: res } = await this.$http.get('/pc-download-list')
      if (res.status === 200) {
        this.dataList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    editItem (id) {
      this.$router.push('/admin/apps/pc/download/edit/' + id)
    },
    async delItem (id) {
      const { data: res } = await this.$http.delete('/pc-download/', { params: { id: id } })
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getData()
      } else {
        this.$message.error(res.msg)
      }
    },
    toAdd () {
      this.$router.push('/admin/apps/pc/download/add')
    },
    changesize (limit) {
      let size = ''
      if (limit < 0.1 * 1024) { // 小于0.1KB，则转化成B
        size = limit.toFixed(2) + 'B'
      } else if (limit < 0.1 * 1024 * 1024) { // 小于0.1MB，则转化成KB
        size = (limit / 1024).toFixed(2) + 'KB'
      } else if (limit < 0.1 * 1024 * 1024 * 1024) { // 小于0.1GB，则转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
      } else { // 其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
      }

      const sizeStr = size + '' // 转成字符串
      const index = sizeStr.indexOf('.') // 获取小数点处的索引
      const dou = sizeStr.substr(index + 1, 2) // 获取小数点后两位的值
      if (dou === '00') { // 判断后两位是否为00，如果是则删除00
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
      }
      return size
    }
  }
}
</script>

<style scoped>
.menu-area{
  padding: 0 0 20px 0;
  text-align: right;
}
.download-img{
  max-width: 100px;
  max-height: 50px;
}
</style>
